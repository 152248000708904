<template>
  <div class="ad_container">
    <div class="ad_word">
      <!--图标-->
      <div class="hint">
        <img :src="trumpet" alt="图标">
        <span>广告</span>
      </div>
      <a class="title">Genesis Digital Assets 在德克萨斯州建立新的自托管</a>
      <a class="title">Genesis Digital Assets 在德克萨斯州建立新的自托管</a>
      <a class="title">Genesis Digital Assets 在德克萨斯州建立新的自托管</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextAds",
  beforeCreate() {
    // 设置CSS样式
    document.querySelector("body").setAttribute("style", "margin: 0;padding: 0;")
  },
  data(){
    return {
      trumpet: require("../static/text_ads/ad.png")
    }
  }
}
</script>

<style scoped>

.ad_word {
  padding: 0 160px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: border-box;
  font-size: 12px;
}

.ad_word > .hint{
  width: 55px;
  display: flex;
  align-items: center;
  color: #959595;
}

.ad_word > .hint > img{
  width: 20px;
  height: 16px;
  margin-right: 5px;
}

.ad_word > .title{
  color: #000000;
  padding: 0 10px;
  cursor: pointer;
  text-decoration: none;
}

</style>